$(function () {
	var el = {
		html: $('html'),
		body: $('body'),
		pagetop: $('.footer__scroll'),
		header: $('.header'),
		footer: $('.footer'),
		gnav: $('#gnav'),
	}
	$(window).scroll(function () {
		if ($(window).scrollTop() > 100) {
			el.pagetop.stop().fadeTo(300, 1);
		} else {
			el.pagetop.stop().fadeTo(200, 0, function () {
				el.pagetop.hide();
			});
		}

		if ($(window).scrollTop() > 100) {
			el.body.removeClass('is_scroll');
		} else {
			el.body.addClass('is_scroll');
		}
	}).scroll();

	var offsetY = 0;
	var time = 600;
	$('a[href^="#"]').click(function () {
		var target = $(this.hash);
		if (this.hash == '#top') {
			target = el.body;
		}
		if (!target.length) return;
		if (!target.hasClass('anchor_target')) {
			offsetY = -el.header.outerHeight()
		}
		var targetY = target.offset().top + offsetY;
		el.html.animate({ scrollTop: targetY }, time, 'swing');
		window.history.pushState(null, null, this.hash);
		return false;
	});
	el.pagetop.click(function () {
		el.html.animate({ scrollTop: 0 }, time, 'swing');
	})

	$('#js_menu').click(function(){
		$('#js_nav').toggleClass('is_show');
		return false;
	})
});
$(window).on('load',function(){
	AOS.init({
		offset: 200,
		duration: 800,
		once: true,
	});
})